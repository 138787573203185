import { Box, Flex } from 'components/box';
import { css } from '@emotion/react';
import { BannerInterface } from 'types/types';
import resourceUrl from '../../../../util/make-url';
import Head from 'next/head';

// NOTE: direct usage of the theme is discouraged in most circumstances.
// DO NOT emulate the following without understanding what you're doing.
import { breakpointsPx } from 'themes/onedayonly';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ChevronLeft as IconPrevious,
  ChevronRight as IconNext,
} from 'react-feather';
import Button from 'components/button';
import ThumbnailBullets from 'components/_shared/widgets/thumbnail-bullets';
import { Link } from 'components/configurable-routing';

const FADE_IN_TIME = 0.2;

const NavigationButton = ({
  variant,
  onClick,
}: {
  variant: 'left' | 'right';
  onClick: () => void;
}) => (
  <Button
    position="absolute"
    variant="flat"
    top="calc(50% - 28px)"
    left={variant === 'left' ? '0' : undefined}
    right={variant === 'right' ? '0' : undefined}
    height="fit-content"
    width="fit-content"
    p="1px"
    zIndex={10}
    color="white"
    shouldFocus={false}
    hoverColor="white"
    fontColor="white"
    onClick={onClick}
  >
    {variant === 'right' ? <IconNext size="50" /> : <IconPrevious size="50" />}
  </Button>
);

const BannerPreloads = ({
  mobileImage,
  tabletImage,
  desktopImage,
}: {
  mobileImage: string;
  tabletImage: string;
  desktopImage: string;
}) => (
  <Head>
    <link
      rel="preload"
      href={mobileImage}
      as="image"
      media={`(max-width: ${breakpointsPx[0] - 1}px)`}
    />
    <link
      rel="preload"
      href={tabletImage}
      as="image"
      media={`(min-width: ${breakpointsPx[0]}px) and (max-width: ${
        breakpointsPx[1] - 1
      }px)`}
    />
    <link
      rel="preload"
      href={desktopImage}
      as="image"
      media={`(min-width: ${breakpointsPx[1]}px)`}
    />
  </Head>
);

const BannerInner = ({
  banner,
  currentIndex = 0,
}: {
  banner: BannerInterface;
  currentIndex?: number;
}) => {
  const mobileImage = resourceUrl(banner.mobileImage.url, {
    isAssetUrl: banner.isAssetUrl,
    quality: 75,
    width: 500,
  });

  const tabletImage = resourceUrl(banner.tabletImage.url, {
    isAssetUrl: banner.isAssetUrl,
    quality: 75,
    width: 900,
  });

  const desktopImage = resourceUrl(banner.desktopImage.url, {
    isAssetUrl: banner.isAssetUrl,
    quality: 75,
    width: 1200,
  });
  return (
    <>
      {currentIndex === 0 && (
        <BannerPreloads
          mobileImage={mobileImage}
          tabletImage={tabletImage}
          desktopImage={desktopImage}
        />
      )}
      <picture>
        <source
          srcSet={mobileImage}
          media={`(max-width: ${breakpointsPx[0] - 1}px)`}
        />
        <source
          srcSet={tabletImage}
          media={`(min-width: ${breakpointsPx[0]}px) and (max-width: ${
            breakpointsPx[1] - 1
          }px)`}
        />
        <img
          aria-label={banner.altText}
          alt={banner.altText}
          src={desktopImage}
          css={css`
            width: 100%;
            margin: 0;
            padding: 0;
          `}
        />
      </picture>
    </>
  );
};

const SingleBanner = ({ banner }: { banner: BannerInterface }) => (
  <Box
    tabIndex={0}
    m={0}
    p={0}
    css={theme => css`
      border-radius: ${theme.radii[3]}px;
      overflow: hidden;
    `}
  >
    {banner.link ? (
      <Link href={banner.link} title={banner.altText} target="_blank">
        <BannerInner banner={banner} />
      </Link>
    ) : (
      <BannerInner banner={banner} />
    )}
  </Box>
);

const BannerCarousel = ({ banners }: { banners: BannerInterface[] }) => {
  const [bannerIndex, setBannerIndex] = useState(0);
  const [didUserClick, setDidUserClick] = useState(false);
  const numBanners = useMemo(() => banners.length, [banners]);

  useEffect(() => {
    let interactionTimeout: NodeJS.Timeout;

    if (didUserClick) {
      interactionTimeout = setTimeout(() => {
        setBannerIndex(prevIndex => (prevIndex + 1) % numBanners);
        setDidUserClick(false);
      }, 10000);
    } else {
      interactionTimeout = setTimeout(() => {
        setBannerIndex(prevIndex => (prevIndex + 1) % numBanners);
      }, 5000);
    }

    return () => clearTimeout(interactionTimeout);
  }, [banners, bannerIndex, numBanners, didUserClick]);

  const clickToBanner = useCallback((index: number) => {
    setBannerIndex(index);
    setDidUserClick(true);
  }, []);

  useEffect(() => {
    setBannerIndex(0);
  }, [banners]);

  const currentBanner = useMemo(() => {
    const [firstBanner] = banners;
    return bannerIndex in banners ? banners[bannerIndex] : firstBanner;
  }, [bannerIndex, banners]);

  if (!currentBanner) return null;

  return (
    <Box>
      <Box
        tabIndex={0}
        m={0}
        p={0}
        position="relative"
        css={theme => css`
          border-radius: ${theme.radii[3]}px;
          overflow: hidden;
        `}
      >
        <NavigationButton
          variant="left"
          onClick={() =>
            clickToBanner(bannerIndex === 0 ? numBanners - 1 : bannerIndex - 1)
          }
        />
        {currentBanner.link ? (
          <Link
            href={currentBanner.link}
            title={currentBanner.altText}
            target="_blank"
          >
            <BannerInner banner={currentBanner} currentIndex={bannerIndex} />
          </Link>
        ) : (
          <BannerInner banner={currentBanner} currentIndex={bannerIndex} />
        )}

        <NavigationButton
          variant="right"
          onClick={() =>
            clickToBanner(bannerIndex === numBanners - 1 ? 0 : bannerIndex + 1)
          }
        />
      </Box>

      <Flex
        width="100%"
        justifyContent="center"
        opacity={0.7}
        mt="-0.9rem"
        css={css`
          animation: fadeIn ${FADE_IN_TIME}s;
          @keyframes fadeIn {
            0% {
              opacity: 0;
            }
            100% {
              opacity: 1;
            }
          }
        `}
      >
        <ThumbnailBullets
          size="small"
          showOnAllDevices
          total={numBanners}
          activeIndex={bannerIndex}
        />
      </Flex>
    </Box>
  );
};

const Banner = ({
  banners,
}: {
  banners?: BannerInterface[] | BannerInterface;
}) => {
  if (!banners) return null;
  const bannersIsArray = Array.isArray(banners);
  if (bannersIsArray && banners.length > 1) {
    return <BannerCarousel banners={banners} />;
  } else if (bannersIsArray && banners.length === 1) {
    return <SingleBanner banner={banners[0]} />;
  } else if (!bannersIsArray && typeof banners === 'object') {
    return <SingleBanner banner={banners} />;
  }
  return null;
};

export default Banner;
